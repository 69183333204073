class DatabaseService {
    constructor() {
        this.companyLocation = '/database/locations.json';
        this.roomsLocation = '/database/products.json';
    }

    debug(message, data){
      //console.log(message, data);
    }
  

  getCompanies = async () => {
    let data=null;
    try
    {
        const response = await fetch(this.companyLocation);
        if(!response.ok) console.error('Error fetching the companies');
        data = await response.json();
        this.debug("getCompanies", data);
    }catch(error){
        console.error('Error fetching the companies:', error)
    }
    return data;
  }

  getRoomByProductId = async(product_id) => {
    let rooms = await this.getAllRooms();
    let room = rooms.filter(room => room.product_id == product_id);
    if(room.length > 0)
      return room[0];
    return null;
  }

  getCompany = async(id) => {
    let companies = await this.getCompanies();
    let company = companies.filter(company => company.id == id);
    if(company.length > 0)
      return company[0];
    return null;
  }

  getRooms = async(companyId) => {

    let data=null;
    try
    {
        const response = await fetch(this.roomsLocation);
        if(!response.ok) console.error('Error fetching the companies');
        data = await response.json();
        data = data.filter(data => data.location_id === companyId);
        this.debug("getRoomsWithCompany", data);
    }catch(error){
        console.error('Error fetching the offices:', error);
    }
    return data;
  }

  getAllRooms = async() => {
    let data=null;    
    try{
      const response = await fetch(this.roomsLocation);
        if(!response.ok) console.error('Error fetching the companies');
        data = await response.json();
        this.debug("getAllRooms", data);
    }catch(error){
      console.error('Error fetching the offices:', error);
    }
    return data;
  }

}

export default DatabaseService;