import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import Header from '../components/Header/Header';
import Banner from '../components/Banner';
import FactoryService from '../services/FactoryService';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Import your CSS file


function App() 
{
    const [account, setAccount] = useState('');
    const [isOwner, setIsOwner] = useState(false);
    const [web3Provider, setWeb3Provider] = useState();
    const [etherBalance, setEtherBalance] = useState(0);
    const [tokenBalance, setTokenBalance] = useState(0);

    const loadBlockchainData = async (web3, account) => {
        try{
            const balance = await web3.eth.getBalance(account);
            setEtherBalance(web3.utils.fromWei(balance, 'ether'));

            FactoryService.CreateService("contract", {web:web3, account:account});
            const contractService = FactoryService.GetService("contract");
            
//            new ContractService(web3, account);
            const tokenBalance = await contractService.getTokenBalance();
            setTokenBalance(tokenBalance.toString());

            const isOwner = await contractService.isOwner(account);
            setIsOwner(isOwner);

        }catch(error)
        {
            setEtherBalance(web3.utils.fromWei(0, 'ether'));
            setTokenBalance("0");
            setAccount(null);
        }
    };

    const onNFTBuyed = () => {
        (async() =>{
            await loadBlockchainData(web3Provider, account);
        })();
    }

    useEffect(() => {
        const init = async () => {
          const provider = await detectEthereumProvider();
          if (provider) {
            const web3 = new Web3(provider);
            setWeb3Provider(web3);
                try {
                    await provider.request({ method: 'eth_requestAccounts' });
                    const accounts = await web3.eth.getAccounts();
                    setAccount(accounts[0]);
        
                    await loadBlockchainData(web3, accounts[0]);
            
                    provider.on('accountsChanged', async (accounts) => {
                        if(accounts.length === 0)
                        {
                            setAccount(null);
                            setEtherBalance(0);
                            setTokenBalance(null);
                        }
                        else{
                            setAccount(accounts[0]);
                            await loadBlockchainData(web3, accounts[0]);
                        }
                    });
                } catch (error) {
                    //console.error("Error requesting accounts: ", error);
                } 
          }
        };
    
        init();
      }, []);

    return (
        <div>
            <Header account={account} balance={etherBalance} tokenBalance={tokenBalance} />      
            <Banner account={account} onNFTBuyed={onNFTBuyed} isOwner={isOwner}/>
        </div>
    );
}

export default App;
