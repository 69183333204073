import React, { useState, useEffect } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FactoryService from '../../services/FactoryService';

const WithdrawCurrencies = () => {

    const [currencies, setCurrencies] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const performWithdraw = (currency) => {
        (async() => {
            let service = FactoryService.GetService("contract");
            try{
                setLoadingData(true);
                (currency.title == "Ethereum") ? await service.withdraw() : await service.withdrawToken();
                await refreshTable();
            }
            catch(error){alert("Error recogiendo desde el contrato")}
            finally
            {
                setLoadingData(false);
            }
        })();

        //withdraw
    }

    const refreshTable = () =>
    {
        (async() => {
            let service = FactoryService.GetService("contract");

            if(service != null){
                let dataCurrencies = [
                    { id: 1, name:"STT", title:"Token", disabled:false, src: 'https://seeklogo.com/images/Z/zetta-ethereum-hashrate-token-zeth-logo-6666F36531-seeklogo.com.png', alt: 'STT' },
                    { id: 2, name:"ETHER",title:"Ethereum", disabled:false, src: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Ethereum_logo_translucent.svg', alt: 'ETH' },
                    //{ id: 3, name:"EURO",title:"Euro", disabled:true, src: 'https://media.istockphoto.com/id/1173483815/es/vector/icono-de-glifo-vectorial-relacionado-con-euro-sign.jpg?s=612x612&w=0&k=20&c=ZujnGv2iPpbrzRsfb8HZX8o26PR11lryRXUgSv7IKvA=', alt: 'EURO' }
                ];  

                let ethers = await service.getEthBalanceFromContract();
                let tokens = await service.getTokenBalanceFromContract();
                let numberOfTransactions = await service.getTotalSupply();

                dataCurrencies[0].amount=tokens.toString();
                dataCurrencies[1].amount=ethers.toString();

                dataCurrencies[0].transactions=numberOfTransactions.toString();
                dataCurrencies[1].transactions=numberOfTransactions.toString();

                setCurrencies(dataCurrencies);
            }
        })();
    }

    useEffect(() => {
        (async() => {
            await refreshTable();                
        })();

    }, []);

    return (
        <div class="container">
            <div class="cryptocurrency-table table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Moneda</th>
                            <th scope="col">En el contrato</th>
                            <th scope="col">Transacciones</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        currencies.map((currency) => (
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <img style={{width: 20}} src={currency.src} alt="image"/>
                                        <h3 class="mb-0">{currency.title}</h3>
                                    </div>
                                </td>
                                <td>{currency.amount}</td>
                                <td>
                                    <span class="trending up">{currency.transactions}</span>
                                </td>
                                <td>
                                    <button class="link-btn" onClick={() => performWithdraw(currency)} type="button" disabled={currency.disabled}> 
                                        {
						                    (loadingData) ?
							                    <FontAwesomeIcon icon={faSpinner} spin={loadingData} size="1x" />
						                    :
                                                <span>Recoger</span>
					                    }
                                        
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div class="more-cryptocurrency-box text-center">
                <a class="default-btn" href="/prices/">
                    <i class="bx bx-purchase-tag-alt"></i> See More Prices
                </a>
            </div>
        </div>
    )};

export default WithdrawCurrencies;