import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup, FormControl, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import FactoryService from '../services/FactoryService';

const CompanyPopup = ({ show, onHide, onSelect }) => {
    const [companies, setCompanies] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredCompanies = companies.filter(company =>
        company.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const openExternalWebSite = (url) =>{
        window.open(url, '_blank');
    };

    useEffect(() => {
        (async() => {
            FactoryService.CreateService("database");
            let service = FactoryService.GetService("database");
            let companies = await service.getCompanies();
            if(companies != null)
                setCompanies(companies);
        })();
    }, []);

    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton>
            <Modal.Title>Selecciona donde alquilar el espacio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
            <InputGroup className="mb-3">
                <FormControl
                placeholder="Buscar empresa"
                aria-label="Buscar empresa"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                />
            </InputGroup>
            </Form>
            <ListGroup>
            {filteredCompanies.map(company => (
                <ListGroup.Item key={company.id}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${company.id}`}>{company.description}</Tooltip>}>
                        <img src={company.image} alt={company.title} style={{ width: '50px', height: '50px', marginRight: '15px' }} />
                    </OverlayTrigger>
                    <div>
                        <a onClick={() => openExternalWebSite(company.googleMapsUrl)} style={{cursor:'pointer'}}><h5>{company.title}</h5></a>
                        <div>
                            
                        {company.tags.map((tag, index) => (
                            <small key={index}>{tag}</small>
                        ))}
                        </div>
                    </div>
                    </div>
                    <Button variant="primary" onClick={() => onSelect(company)}>
                    Seleccionar
                    </Button>
                </div>
                </ListGroup.Item>
            ))}
            </ListGroup>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Cerrar</Button>
        </Modal.Footer>
        </Modal>
    );
};

export default CompanyPopup;
