import React, { useState, useEffect } from 'react';
import { Container, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const currencies = [
  { id: 1, name:"STT", src: 'https://seeklogo.com/images/Z/zetta-ethereum-hashrate-token-zeth-logo-6666F36531-seeklogo.com.png', alt: 'STT' },
  { id: 2, name:"ETHER", src: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Ethereum_logo_translucent.svg', alt: 'ETH' },
  { id: 3, name:"EURO", src: 'https://media.istockphoto.com/id/1173483815/es/vector/icono-de-glifo-vectorial-relacionado-con-euro-sign.jpg?s=612x612&w=0&k=20&c=ZujnGv2iPpbrzRsfb8HZX8o26PR11lryRXUgSv7IKvA=', alt: 'EURO' }
];

const CurrencySelect = ({onCurrencySelection}) => {
  const [selectedImageId, setSelectedImageId] = useState(null);

  const handleImageClick = (id) => {
    let currency = currencies.find(currency => currency.id === id);

    if(currency.name != "EURO"){
      setSelectedImageId(id);
		  onCurrencySelection(currency.name);
    }
    else{
      alert("Estamos trabajando para poder ofrecer esta opción pronto.");
    }
  };

  useEffect(() =>{
    setSelectedImageId(1);
  }, []);

  return (
    <Container>
      <div className="image-selector">
        {currencies.map((currency) => (
          <div
            key={currency.id}
            className={`image-container ${selectedImageId === currency.id ? 'selected' : ''}`}
            onClick={() => handleImageClick(currency.id)}
          >
            <Image src={currency.src} alt={currency.alt} thumbnail />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default CurrencySelect;
