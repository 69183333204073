import config from '../config';

class ApiService {
  constructor() {
    this.apiUrl = config.apiUrl; // Asumiendo que la URL completa está en esta variable
  }

  //async test_sendMail(email, code, date){ throw new Error('error');}

  async sendMail(email, code, date) {
    const postData = {
      email: email,
      code: code,
      date: date
    };

    try {
      const response = await fetch(this.apiUrl+"/mail", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      });

      if (!response.ok) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      const data = await response.json(); // Assuming the response is text
      return data.body[0];
    } catch (error) {
      throw new Error('error');
    }
  }
}

export default ApiService;