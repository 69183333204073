import React, { useState } from 'react';
import CompanyPopup from './CompanyPopup';

const BannerText = ({onSelectCompany}) => {

const [showPopup, setShowPopup] = useState(false);
const [company, setCompany] = useState(null);

const handleShowPopup = () => setShowPopup(true);
const handleClosePopup = () => setShowPopup(false);

const handleSelectCompany = (company) => {
    onSelectCompany(company);
	setCompany(company);
	handleClosePopup();
};

return (
	<div className="row align-items-center m-0">
		<div className="row align-items-center m-0">
			<div className="col-xl-6 col-lg-6 col-md-12 p-0">
				<div className="main-banner-content">
					{
						company ?
						(
							<h1>¡Alquila en {company.title}!</h1>
						) : (
							<h1>¡Alquila oficinas con tecnología Blockchain!</h1>
						)
					}
					<p>¡Descubre una nueva forma de alquilar oficina usando blockchain! Disfruta de la tranquilidad en cada transacción, sabiendo que tu <strong>reserva está segura</strong> y tu <strong>privacidad protegida</strong>. ¡Únete a nosotros hoy y experimenta el futuro del alquiler de oficinas!</p>
					
					{
						company ?
						(
							<button onClick={handleShowPopup} className="default-btn" target="_blank">
								<i className="bx bxs-user"></i> Cambia de espacio
							</button>
						) : (
							<button onClick={handleShowPopup} className="default-btn" target="_blank">
								<i className="bx bxs-user"></i> Selecciona espacio
							</button>
						)
					}
				</div>
			</div>
			<CompanyPopup
		        show={showPopup}
		        onHide={handleClosePopup}
		        onSelect={handleSelectCompany}
      		/>
			<div className="col-xl-4 col-lg-12 col-md-12 p-0">
				<div className="main-banner-image">
					<img src="/images/banner/banner-img1.png" alt="image" />
				</div>
			</div>
		</div>
	</div>
)};

export default BannerText;