import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import TicketDetails from './TicketDetails';

const StatusIndicatorVariables = {
    SUCCESS: 'success',
    ERROR: 'error',
    LOADING: 'loading'
}

const CreateTicketStatusIndicator = ({ show, status, title, message, ticketReceipt, onClose }) => {
    let icon;
    let color;

    switch (status) {
        case 'success':
            icon = faCheckCircle;
            color = '#00a79d';
            break;
        case 'error':
            icon = faTimesCircle;
            color = '#dc3545';
            break;
        case 'loading':
            icon = faSpinner;
            color = '#5466F9';
            break;
        default:
            icon = faSpinner;
            color = '#5466F9';
    }

    const getImage = () =>
    {
        let image = "https://cdn.dribbble.com/users/400493/screenshots/7322525/ticket.gif"
        switch (status) {
            case 'success':
                image = "";
                break;
            case 'error':
                image = "https://cdn.svgator.com/images/2024/04/electrocuted-caveman-animation-404-error-page.gif";
                break;
            case 'loading':
                image = "https://cdn.dribbble.com/users/400493/screenshots/7322525/ticket.gif";
                break;
            default:
                image = ""
                break;
        }
        return image;
    }

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header style={{borderBottom: 'none'}} closeButton>
            </Modal.Header>
            <Modal.Body className="text-center">
                <FontAwesomeIcon icon={icon} spin={status === StatusIndicatorVariables.LOADING} size="3x" style={{ color }} />
                <h4 className="mt-3">{title}</h4>                
                <p>{message}</p>
                {
                    getImage() !== "" &&
                    (
                        <img src={getImage()} style={{width:200}} alt="" />
                    )
                }                
                {
                    ((status !== 'loading') && (status !== 'error')) &&
                    (
                        <TicketDetails ticketReceipt={ticketReceipt}></TicketDetails>
                    )
                }                
            </Modal.Body>
            <Modal.Footer style={{borderTop: 'none'}}>
            </Modal.Footer>
        </Modal>
    );
};

export { CreateTicketStatusIndicator, StatusIndicatorVariables };
