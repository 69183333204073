import React, {useState} from 'react';
import NFTCalendar from './NFTCalendar';

const AccountInfo = ({account, balance, tokenBalance}) => {

	const [showNFTs, setShowNFTs] = useState(false);

	const handleShowPopup = () => setShowNFTs(true);
	const handleClosePopup = () => setShowNFTs(false);

	return (
		<div className="d-flex align-items-center">
		{
			(tokenBalance > 0 && account) &&
			(
				<div className="option-item">
					<a className="login-btn" href="#">
						<i className="fab fa-ethereum"></i> {tokenBalance} tokens
					</a>
				</div>
			)
		}														
		{
			(balance > 0 && account) &&
			(
				<div className="option-item">
					<a className="login-btn" href="#">
						<i className="fab fa-ethereum"></i> {Math.round(balance*10000)/10000} ethers
					</a>
				</div>
			) 
		}
			<div className="option-item">
			{
				account && 
				(
					<button onClick={handleShowPopup} className="default-btn" target="_blank">
						<i className="bx bxs-user"></i> Reservas
					</button>											
				)
			}
			</div>
			<div className="option-item d-none d-lg-block">
			{
				account ? 
				(
					<span className="default-btn shortened-account">
						<i className="bx bxs-contact"></i> {account}
					</span>
				) : (
					<a className="default-btn" href="#">
						<i className="bx bxs-contact"></i> Login Metamask
					</a>
				)
			}
			</div>
			<NFTCalendar display={showNFTs} onHide={handleClosePopup}></NFTCalendar>
		</div>
	)
};

export default AccountInfo;