import React, { useState } from 'react';
import BannerText from './BannerText';
import NFTBuy from './NFTBuy';
import FactoryService from '../services/FactoryService';
import AdminBannerDashboard from './Dashboard/AdminBannerDashboard';


const Banner = ({account, onNFTBuyed, isOwner}) => {

	const [company, setCompany] = useState(null);
	const [displayOwner, setdisplayOwner] = useState(false);

	const handleSelectCompany = (company) => {
		setCompany(company);
		let service = FactoryService.GetService("config");
		service.setCompany(company);
	};

	const handleDisplayOwnerSite = () => {
		setdisplayOwner(!displayOwner);
	}

	const displayMessage = () => {
		return (displayOwner) ? "Quitar Administración" : "Ver Administración";
	}

return (
	<div className="main-banner-area">
		<div className="container">
			{
				isOwner &&
				(
					<button onClick={handleDisplayOwnerSite} className="default-btn" target="_blank">
						<i className="bx bxs-user"></i> {displayMessage()}
					</button>
				)
			}
			{

				!displayOwner ? 
				(
					<div>
						<BannerText onSelectCompany={handleSelectCompany}/>
						<NFTBuy account={account} onNFTBuyed={onNFTBuyed} company={company}/>	
					</div>
				) : (
						<AdminBannerDashboard />
					
				)
			}
		</div>
		<div className="shape1">
			<img src="/images/shape/shape1.png" alt="image" />
		</div>
		<div className="shape2">
			<img src="/images/shape/shape2.png" alt="image" />
		</div>
		<div className="shape3">
			<img src="/images/shape/shape3.png" alt="image" />
		</div>
		<div className="shape5">
			<img src="/images/shape/shape5.png" alt="image" />
		</div>
		<div className="shape9">
			<img src="/images/shape/shape9.png" alt="image" />
		</div>
	</div>
)};

export default Banner;