import React, {useState, useEffect} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import TicketDetails from '../Tickets/TicketDetails';
import FactoryService from '../../services/FactoryService';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const localizer = momentLocalizer(moment);

/*
Hay un problema en el renderizado del mapa cuando hay muchos elementos, en ese momento
carga muchos, y tarda bastante, hay que gestionarlo de alguna forma, o ver si debería de poner
solamente que se vea por semanas.
Con 400 eventos [para una cuenta] tarda más de un minuto en cargar todo. 
*/
const NFTCalendar = ({ display, onHide, isUser=true, productId="" }) => {
    const [events, setEvents] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [ticketReceipt, setTicketReceipt] = useState('');
    const [loadingData, setLoadingData] = useState(false);

    const eventStyleGetter = (event) => {
        let backgroundColor = '#' + event.officeColor;
        let style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    };

    const getTitleString = (id) => {
        let office = rooms.filter(element => element.product_id == id)[0];
        let company = companies.filter(element => element.id == office.location_id)[0];
        return `${office.title} [${company.title}]`; // ${company.title}] `;
    }

    const getColor = (id) => {
        let office = rooms.filter(element => element.product_id == id)[0];
        return office.color;
    }

    const mapElement = (hash_id, element) => {
        let mappedElement = {
            hash_id: hash_id,
            title: getTitleString(element.id),
            start: new Date(parseInt(element.start*1000)), 
            end: new Date(parseInt(element.end*1000)), 
            officeColor: getColor(element.id)
        };
        return mappedElement;
    }

    const loadData = () => {
        (async() => {
            let service = FactoryService.GetService("database");
            setRooms(await service.getAllRooms());
            setCompanies(await service.getCompanies());
            setDataLoaded(true);
        })();
    }

    const loadEvents = () => {
        (async() =>{
            try 
            {
                setLoadingData(true);
                let contractService = FactoryService.GetService("contract");
                const nfts = (isUser) ? await contractService.getNFTs() : await contractService.getNFTsByProductId(productId);
                if(nfts){
                    let events = nfts.map(element => mapElement(element.hash, JSON.parse(element.uri)));
                    setEvents(events);
                } 
            } catch (error) {
                console.log(error);
            }
            finally{
                setLoadingData(false);
            }
        })();
    }

    useEffect(() => {
        setDataLoaded(false);
        if(display){
            setTicketReceipt('');
            FactoryService.CreateService("database");
            if(rooms.length === 0 || companies.length === 0) loadData();
            else setDataLoaded(true);                
        }
    }, [display]);

    useEffect(() => {
        if(dataLoaded){
            loadEvents();
        }
    }, [dataLoaded]);

    const handleSelectEvent = (event) => {
        if(isUser)
            setTicketReceipt(event.hash_id);
    };

    const handleBack = () => {
        setTicketReceipt("");
    }

    return (
        <Modal show={display} onHide={onHide} size="lg" centered>
        {
            (ticketReceipt != "")
            ?
                <Modal.Header style={{borderBottom: 'none'}} closeButton>
                </Modal.Header>
            :
                <Modal.Header closeButton>
                    <Modal.Title>
                        Estas son tus reservas hasta ahora  
                        {
                            (loadingData) &&
                            <FontAwesomeIcon icon={faSpinner} spin={loadingData} size="1x" />
                        }
                        
                    </Modal.Title>
                </Modal.Header>
        }
        <Modal.Body className="text-center">
            {
                (ticketReceipt != "")
                ? 
                    <TicketDetails ticketReceipt={ticketReceipt} />
                :
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    eventPropGetter={eventStyleGetter}
                    onSelectEvent={handleSelectEvent}
                />
            }
            
        </Modal.Body>
        {
            (ticketReceipt != "")
            ?
                <Modal.Footer style={{borderTop: 'none'}}>
                    <Button variant="secondary" onClick={handleBack}>Atrás</Button>
                </Modal.Footer>
            :
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>Cerrar</Button>
                </Modal.Footer>
        }
        </Modal>
    );
};

export default NFTCalendar;
