import React, {useState, useEffect} from 'react';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import FactoryService from '../../services/FactoryService';
import moment from 'moment';
//import ApiService from '../../services/ApiService';


const TicketDetails = ({ ticketReceipt }) => {

    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [info, setInfo] = useState({});

    useEffect(() => {
        //Aquí recogeremos el contenido de los tickets.
        FactoryService.CreateService("api", null);
        loadInfo();
    }, [ticketReceipt]);

    const sendMail = async () =>
    {
        try {
            let apiService = FactoryService.GetService("api");
            const response = await apiService.sendMail(email, ticketReceipt, '');
            if(response === "Sucess")
            {
                setSuccess(true);
                setError(false);
            }
          } catch (error) {
            setError(true);
            setSuccess(false);
          }
    }

    const convertTimestampToLocalTime = (timestamp) => {
        return moment.unix(timestamp).local().format('DD/MM/YYYY HH:mm');
      };
    
    const loadInfo = () => {
        (async() =>{
            try 
            {
                let contractService = FactoryService.GetService("contract");
                const tokenUri = await contractService.getTokenInfoFromReceipt(ticketReceipt);
                let metadata = JSON.parse(tokenUri);

                let databaseService = FactoryService.GetService("database");
                let office = await databaseService.getRoomByProductId(metadata.id);
                let company = await databaseService.getCompany(office.location_id);
                let startDate = convertTimestampToLocalTime(metadata.start);
                let endDate = convertTimestampToLocalTime(metadata.end);

                let dataInfo = {
                    companyName: company.title,
                    officeName : office.title,
                    start: startDate,
                    end: endDate
                }

                setInfo(dataInfo);
                
            } catch (error) {
                console.log(error);
            }
        })();
    }
    
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const getImage = () => {
        return "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data="+ticketReceipt;
    }

    return (
        <div>
        {
            (info && info.officeName) && (
                <div>
                    <h2>{info.officeName} [{info.companyName}]</h2>
                    <small>{info.start} - {info.end}</small>
                </div>
            )
        }
        <img src={getImage()} style={{width:200}} alt="" />
        {
            (!success) 
            ?
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Envie el código QR por correo:</Form.Label>
                        <Form.Control type="text" placeholder="tudirección@decorreo.es" value={email} onChange={handleEmailChange} />
                    </Form.Group>
                    <Button variant="primary" className="mt-2 ml-2" onClick={sendMail}>
                        Enviar por correo
                    </Button>
                </Form>
            : 
                <div style={{ marginTop: '25px', marginLeft: '15px', marginRight: '15px' }}>
                    <p><FontAwesomeIcon icon={faCheckCircle} size="1x" style={{ color: '#00a79d' }} /> Correo enviado correctamente a: <strong>{email}</strong></p>
                </div>
        }
        {
            (error) &&
            <div style={{ marginTop: '25px', marginLeft: '15px', marginRight: '15px' }}>
                <p><FontAwesomeIcon icon={faTimesCircle} size="1x" style={{ color:'#dc3545' }} /> Error al enviar el QR a: <strong>{email}</strong></p>
            </div>

        }
        </div>
    );
};

export default TicketDetails;