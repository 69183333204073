import React, { useState, useEffect }  from 'react';
import { Form } from 'react-bootstrap';
import CurrencySelect from './CurrencySelect';
import FactoryContractService from '../services/FactoryService';
import { CreateTicketStatusIndicator, StatusIndicatorVariables} from './Tickets/CreateTicketStatusIndicator';
import FactoryService from '../services/FactoryService';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NFTCalendar from './Header/NFTCalendar';

const NFTBuy = ({account, company, onNFTBuyed}) => {

	const [startDate, setStartDate] = useState('');
	const [minDate, setMinDate] = useState('');
	const [hours, setHours] = useState(0);
	const [office, setOffice] = useState('');
	const [cost, setCost] = useState(0);
	const [currency, setCurrency] = useState("STT");
	const [loadedOffice, setLoadedOffices] = useState([]);
	const [isAvailable, setIsAvailable] = useState(false);
	const [status, setStatus] = useState(StatusIndicatorVariables.ERROR);
	const [modalTitle, setModalTitle] = useState('Espere por favor');
	const [message, setMessage] = useState('Estamos preparando su ticket, no refresque la pantalla');
	const [showStatusModal, setShowStatusModal] = useState(false);
	const [ticketReceipt, setTicketReceipt] = useState('');
	const [officeIsNotAvailable, setOfficeIsNotAvailable] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [showNFTs, setShowNFTs] = useState(false);
	const [officeId, setOfficeId] = useState('');

	const [isApproved, setIsApproved] = useState(false);

	const handleShowPopup = () => {
		let elementOffice = loadedOffice.find(element => element.id === parseInt(office));
		setOfficeId(elementOffice.product_id);
		setShowNFTs(true);
	}
	const handleClosePopup = () => setShowNFTs(false);


	const readyToBuy = () => {
		let finalCost = cost * hours;
		return (checkAccountIsSet() && finalCost > 0 &&  startDate && company)
	};

	const checkAccountIsSet = () => {
		return account !== "";
	};

	const shouldBeDisabled = () => {
		return account === "" || company == null;
	}

	const handleOfficeChange = (e) => {
		setOffice(e.target.value);
		setCostFinally(e.target.value, currency);
		setOfficeIsNotAvailable(false);
		setIsAvailable(false);
		setIsApproved(false);
	};

	const checkMissingVariables = (...variables) =>{
		return variables.filter(variable => !variable);
	}

	const getLabelForButtonWithPrice = () => {
		let message = "";
		if(readyToBuy())
		{
			if(isAvailable){
				let finalCost = cost * hours;
				message = (finalCost === 0) ? "Alquilar espacio" : "Alquilar espacio por "+finalCost+" "+currency;

				if(isTokenUsed() && !isApproved)
					message = "Aprobar la transferencia de "+finalCost+" "+currency;
			}
			else{
				message = "Comprueba la disponibilidad";
			}
		}
		else{
			//Vamos a pasar a hacer las comprobaciones
			let prefix = "Indica la ";
			message = "Alquilar espacio";
			if(checkMissingVariables(startDate, cost, hours) <= 1)
				prefix = "Solo te falta la ";

			if(!hours)
				message = prefix + "cantidad de horas";
			if(!startDate)
				message = prefix + "fecha";
			if(!office)
				message = prefix + "oficina";
			
		}
		return message;
	}

	const isTokenUsed = () => 
	{
		return (currency === "STT");
	}

	const performButton = () =>
	{
		if(!isAvailable && readyToBuy())
		{
			//Aquí debemos de comprobar si es correcto, si lo es, tenemos que 
			
			//setIsAvailable(true);
			let elementOffice = loadedOffice.find(element => element.id === parseInt(office));
			const service = FactoryContractService.GetService("contract");
			setLoadingData(true);
			service.productIsAvailable(elementOffice.product_id, startDate, hours).then(result => 
			{
				setLoadingData(false);
				setIsAvailable(result);
				if(!result){
					setOfficeIsNotAvailable(true);
				}				
			});
		}
		
		if(isAvailable && readyToBuy())
		{
			const service = FactoryContractService.GetService("contract");
			let amountToSent = cost * hours;
			let useTokens = isTokenUsed();
			if(useTokens && !isApproved)
			{
				service.areTokensAllowance(amountToSent).then(result =>
				{
					if(!result){
						service.approveTokenTransfer(amountToSent).then(result => 
						{
							if(result) setIsApproved(true);
							else setIsApproved(false);
						});
					}
					else{
						setIsApproved(true);
					}
				});
				
				
			}
			else{
				setShowStatusModal(true);
				setStatus(StatusIndicatorVariables.LOADING);
				setModalTitle("Espere por favor");
				setMessage("Estamos preparando su ticket, no refresque la pantalla");

				let elementOffice = loadedOffice.find(element => element.id === parseInt(office));			

				service.buyNFTAsync(elementOffice.product_id, startDate, hours, amountToSent, useTokens)
				.then(result => 
				{
					if(result.success)
					{
						setModalTitle("¡Enhorabuena!");
						setStatus(StatusIndicatorVariables.SUCCESS);
						setMessage("Si ticket ha sido creado correctamente.");
						setTicketReceipt(result.data);
						setOfficeIsNotAvailable(false);
						setIsAvailable(false);
						onNFTBuyed();
					}
					else{
						setModalTitle("¡Oops! Hay un problema");
						setStatus(StatusIndicatorVariables.ERROR);
						setMessage(result.message);
					}
				});
			}
		}
	}

	const getMinDate = () =>
	{
		if(minDate === "")
		{
			// Calcular la fecha y hora actual
			const now = new Date();
			const year = now.getFullYear();
			const month = String(now.getMonth() + 1).padStart(2, '0');
			const day = String(now.getDate()).padStart(2, '0');
			const hours = String(now.getHours()).padStart(2, '0');
			const minutes = String(now.getMinutes()).padStart(2, '0');

			// Formatear la fecha y hora para el atributo 'min'
			const minDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
			setMinDate(minDateTime);
		}
		return minDate;
	}

	const setCostFinally = (office_id, currencyName) => {
		if(office_id !== "")
		{
			let elementOffice = loadedOffice.find(element => element.id === parseInt(office_id));
			let price = getPrice(elementOffice,currencyName);
			setCost(price);
		}
	}

	const getPrice = (elementOffice, currencyName) => {
		let mapCurrencyNameToField = {
			"STT": "tokenPrice",
			"ETHER": "price",
			"EURO" : "eurosPrice"
		};
		return elementOffice[mapCurrencyNameToField[currencyName]];
	}

	const onHandleCurrencySelection = (currencyName) =>
	{
		setCurrency(currencyName);

		if(loadedOffice.length > 0)
			setCostFinally(office, currencyName);
	}

	///Utilizamos el useEffect cada vez que cambie company.
	useEffect(() => {
		setIsAvailable(false);
		setIsApproved(false);

		if(company)
		{
			(async() => {
				FactoryService.CreateService("database");
				let service = FactoryService.GetService("database");
				let offices = await service.getRooms(company.id);
				if(offices != null)
					setLoadedOffices(offices);
			})();
		}

	}, [company]);

	return (
		<Form>			
			<div className="main-banner-box">
				{
					company ?
					(
						<h3 style={{marginBottom:20}}>Alquila en {company.title}</h3>
					) : (
						<h3 style={{marginBottom:20}}>Compra acceso a una oficina</h3>
					)
				}
				<div className="currency-selection">
					<Form.Control disabled={shouldBeDisabled()} style={{fontSize:20, fontWeight: 'bold'}} as="select" value={office} onChange={handleOfficeChange}>
						<option value="" disabled>
							Selecciona la oficina...
						</option>
						{
							loadedOffice.map(oficina => (
								<option key={oficina.id} value={oficina.id}>{oficina.title} [{getPrice(oficina,currency)} {currency}]</option>
							))
						}
					</Form.Control>			
				</div>
				<div className="currency-selection d-flex align-items-center">
					<label className="me-2">DAY</label>
					<Form.Control
						disabled={shouldBeDisabled()}
						className="me-2"
						type="datetime-local"
						style={{ width: '70%' }}
						value={startDate}
						onChange={(e) => { setOfficeIsNotAvailable(false); setIsAvailable(false);setIsApproved(false); setStartDate(e.target.value); }}
						min={getMinDate()}
					/>	
					{
						(office) &&
						(<button style={{ width: '30%' }} onClick={handleShowPopup} type="button" disabled={shouldBeDisabled()}><i className="bx bxs-spreadsheet"></i> Agenda</button>)
					}					
				</div>				
				<div className="currency-selection">
					<label>Horas</label>
					<Form.Control
						disabled={shouldBeDisabled()}
						type="number"
						value={hours}
						onChange={(e) => { setOfficeIsNotAvailable(false); setIsAvailable(false);setIsApproved(false); setHours(e.target.value);}}
					/>
				</div>
				<div className="currency-selection">
					<CurrencySelect onCurrencySelection={onHandleCurrencySelection}/>
				</div>
				<button onClick={performButton} type="button" disabled={shouldBeDisabled()}>
					{
						(loadingData) ?
							<FontAwesomeIcon icon={faSpinner} spin={loadingData} size="1x" />
						:
							<i className="bx bxs-hand-right"></i>
					}
					{!checkAccountIsSet() ? 'Accede para comprar' : getLabelForButtonWithPrice()}					 
				</button>
				{
					(officeIsNotAvailable) &&
					(
						<p style={{color: "#d63384", textAlign:"center"}}>No es posible alquilar esta oficina a esta hora</p>
					)
				}
				<CreateTicketStatusIndicator show={showStatusModal} ticketReceipt={ticketReceipt} title={modalTitle} status={status} message={message} onClose={() => setShowStatusModal(false)} />
				<NFTCalendar display={showNFTs} onHide={handleClosePopup} isUser={false} productId={officeId}></NFTCalendar>
			</div>
		</Form>
	);
}

export default NFTBuy;