import React, {useState} from 'react';
import AccountInfo from './AccountInfo';

const Header = ({account, balance, tokenBalance}) => {

	const [showMenu, setshowMenu] = useState(true);

	const toggleMenuButton = () => {setshowMenu(!showMenu);}

	return (
		<div id="navbar" className="navbar-area">
			<div className="raimo-responsive-nav">
				<div className="container">
					<div className="raimo-responsive-menu">
						<div className="hamburger-menu" onClick={toggleMenuButton}>
							<i className="bx bx-menu"></i>
						</div>
						<div className="logo">
							<a href="/web">
								<img src="/images/logo-company-small.png" alt="logo" />
							</a>
						</div>
					</div>
				</div>
			</div>
			<nav className={showMenu ? "navbar navbar-expand-md navbar-light show" :  "navbar navbar-expand-md navbar-light hide-menu"}>
				<div className="container">
					<a className="navbar-brand" href="/web">
						<img src="/images/logo-company-small.png" alt="logo" />
					</a>
					<div className="collapse navbar-collapse mean-menu">
						<div className="others-option">
							<AccountInfo account={account} balance={balance} tokenBalance={tokenBalance} />
						</div>
					</div>
				</div>
			</nav>
		</div>
	)
};

export default Header;