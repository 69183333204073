class ConfigService {
  constructor() {
    this.company  = null;
  }

  setCompany(company){
    this.company = company;
  }

  getCompany(){
    return this.company ;
  }

}

export default ConfigService;