import ContractService from "./ContractService";
import ApiService from "./ApiService";
import DatabaseService from "./DatabaseService";
import ConfigService from "./ConfigService";

const FactoryServiceKey = {
    Contract: 'contract',
    Api: 'api',
    Database: 'database',
    Config: 'config'
}

class FactoryService {

    constructor(){
        this.contractService = null;
        this.apiService = null;
        this.databaseService = null;
        this.configService = new ConfigService();
    }

    GetService(key) {
        let service = null;
        switch(key)
        {
            case FactoryServiceKey.Contract:
                service = this.contractService;
                break;
            case FactoryServiceKey.Api:
                service = this.apiService;
                break;
            case FactoryServiceKey.Database:
                service = this.databaseService;
                break;
            case FactoryServiceKey.Config:
                service = this.configService;
                break;
            default:
                service = null;
                break;
        }
        return service;
    }

    CreateService(key, dataArguments)
    {
        switch(key)
        {
            case FactoryServiceKey.Contract:
                this._createContractService(dataArguments.web, dataArguments.account);
                break;
            case FactoryServiceKey.Api:
                this._createApiService();
                break;
            case FactoryServiceKey.Database:
                this._createDatabaseService();
                break;
            default:
                break;
        }
    }
    
    /*
        Este método nos permite modificar las variables web3 y account
    */
    _createContractService(web3, account){
        this.contractService =  new ContractService(web3, account);
    }

    _createApiService(){
        this.apiService = new ApiService();
    }

    _createDatabaseService(){
        this.databaseService = new DatabaseService();
    }
}

const instance = new FactoryService();
export default instance;